import React, { Component } from 'react'
import { match } from 'react-router'
import DatePicker from '../../UI/date-picker'
import { confirmAlert } from 'react-confirm-alert'
import moment from "moment"
import classNames from "classnames"
import Spinner from '../../UI/spinner'

import './styles.scss'

interface AccessDatesState {
  dateBegin: any,
  dateEnd: any,
  dateBeginOld: any,
  dateEndOld: any,
  isFetching: boolean
}

interface AccessDatesProps extends PermissionInjectProps, ComponentBaseProps {
  match: match<{ userId }>,
  access: any,
  selectedKey: any,
  handleSubmit: any
}
export default class AccessDates extends Component<AccessDatesProps, AccessDatesState> {
  constructor(props) {
    super(props)
    this.state = {
      dateBegin: null,
      dateEnd: null,
      dateBeginOld: null,
      dateEndOld: null,
      isFetching: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { access } = this.props
    if(access.dateBegin !== prevProps.access.dateBegin || access.dateEndOld !== prevProps.access.dateEndOld){
      this.setState({ dateBeginOld: moment(access.dateBegin), dateEndOld: moment(access.dateEnd) })
    }
  }

  onChangeDateBegin = (value) => {
    this.setState({ dateBegin: value })
  }

  onChangeDateEnd = (value) => {
    this.setState({ dateEnd: value })
  }

  updateAccesGroups = (idAccess, value, dateBeginStruct, dateEndStruct) => {
    const { handleSubmit } = this.props

    const now = new Date();

    const dateBegin = dateBeginStruct ? {
      day: moment(dateBeginStruct).date(),
      month: moment(dateBeginStruct).month() + 1,
      year: moment(dateBeginStruct).year(),
    } : {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    }

    const dateEnd = dateEndStruct ? {
      day: moment(dateEndStruct).date(),
      month: moment(dateEndStruct).month() + 1,
      year: moment(dateEndStruct).year(),
    } : {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
    }

    handleSubmit(idAccess, value, dateBegin, dateEnd)

    setTimeout(() => {
      this.setState({ isFetching: false })      
    }, 1000);
  }

  onAccessChange = access => {
    const { t, handleSubmit } = this.props
    const { dateBegin, dateEnd } = this.state
    const { idAccess, groupId, value } = access

    this.setState({ isFetching: true }, () => {
      if (idAccess === 1) {
        confirmAlert({
          title: t('access.admin'),
          message: t('access.admin.verification'),
          buttons: [
            {
              label: t('yes'),
              onClick: () => handleSubmit(idAccess, value, dateBegin, dateEnd),
            },
            {
              label: t('no'),
              onClick: () => { return true; },
            },
          ],
        })
      } else {
        this.updateAccesGroups(idAccess || groupId, value, dateBegin, dateEnd)
      }
    })
  }

  render() {
    const { access, selectedKey, t } = this.props
    const { dateBegin, dateEnd, dateBeginOld, dateEndOld, isFetching } = this.state
    const disabled = selectedKey !== "accessGroups" ? false : !dateBegin || !dateEnd
    const noDataSelected = JSON.stringify(dateBeginOld) === JSON.stringify(dateBegin) && JSON.stringify(dateEndOld) === JSON.stringify(dateEnd)

    return (
      <div
        className={classNames({
          'tr-card': true,
          'mb-20': selectedKey === "accessGroups",
        })}
      >
        <div className="access-point-description">
          <div>{(access.idAccess || access.groupId) + '. ' + access.name}</div>
          <div className="input-container">
            {selectedKey === "accessGroups" ? <>
              <button
                type="button"
                disabled={access.value || Number(access.groupId) !== 1 ? !dateBegin || !dateEnd || noDataSelected : false}
                className={classNames({
                  'button': true,
                  'bg-dark': !access.value || isFetching,
                  'disabled': Number(access.groupId) !== 1 ? !dateBegin || !dateEnd || noDataSelected : false || isFetching,
                  'button-orange': access.value,
                })}
                onClick={() => noDataSelected ? {} : this.onAccessChange(access)}
              >
                {access.value ? t('save.changes') : t("activate")}
                {isFetching && <Spinner />}
              </button>
            </> : <>
              <input
                type={selectedKey === 'accessPoints' ? 'checkbox' : 'radio'}
                name={access.name}
                value={access.name}
                id={`access_${access.name}`}
                checked={access.value}
                {...{ disabled }}
                onChange={() => this.onAccessChange(access)}
              />
              <label htmlFor={`access_${access.name}`} className="loading-place-checkbox" />
            </>}
          </div>
        </div>
        {selectedKey === "accessGroups" && Number(access.groupId) !== 1 && <div className="access-dates">
          <div className="banner-line-description">
            <div className="date-label">{t('activation.from')}</div>
            <div className="banner-input-field date-field">
              <DatePicker
                initialValue={dateBegin || access.dateBegin}
                onChangeDate={(e) => this.onChangeDateBegin(e)}
                setToLocaleDateString
              />
            </div>
          </div>
          <div className="banner-line-description">
            <div className="date-label">{t('activation.to')}</div>
            <div className="banner-input-field date-field">
              {/* {dateBegin} */}
              <DatePicker
                initialValue={dateEnd || access.dateEnd}
                onChangeDate={(e) => this.onChangeDateEnd(e)}
                setToLocaleDateString
              />
            </div>
          </div>
        </div>}
      </div>
    );
  }
}